import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  from,
  InMemoryCache,
  NormalizedCacheObject,
  split,
  TypePolicies,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { isServer } from '@flyer/utils';
// import * as Sentry from '@sentry/nextjs';
import merge from 'deepmerge';
import { createClient } from 'graphql-ws';
import { head, isEqual } from 'lodash-es';
import { useMemo } from 'react';
import * as Sentry from '@sentry/nextjs';
import { ENVIRONMENT } from '@/lib/config';

const WsApi = process.env.NEXT_PUBLIC_GRAPHQL_WS_API as string;
const WsApiV2 = process.env.NEXT_PUBLIC_GRAPHQL_WS_API_V2 as string;
const ClientApi = '/api/graphql';
const ClientApiV2 = '/api/v2/graphql';
const ServerApi = `${process.env.NEXT_PUBLIC_APP_API as string}/graphql`;
const ServerApiV2 = process.env.NEXT_PUBLIC_GRAPHQL_API_V2 as string;

Sentry.init({
  dsn: ENVIRONMENT.DSN_SENTRY,
  tracesSampleRate: 0.1,
  sampleRate: 0.5,
  normalizeDepth: 10,
  sendDefaultPii: true,
  ignoreErrors: [
    /zalo/,
    /ChunkLoadError/,
    /Error: Minified React/,
    /UnhandledRejection/,
    /SyntaxError/,
    /Remote Config/,
    /Activation code not found/,
    /evaluating 'a.fa'/,
    /Transaction timed out due/,
    /globalThis is not defined/,
    /isReCreate is not defined/,
    /The operation is insecure/,
    /request was interrupted/,
  ],
  denyUrls: [
    /graph\.facebook\.com/i,
    /connect\.facebook\.net\/en_us\/all\.js/i,
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    /extensions\//i,
    /^chrome:\/\//i,
    /127\.0\.0\.1:4001\/isrunning/i,
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
  ],
  beforeSendTransaction(event) {
    if (
      /zalo/.test(event.transaction || '') ||
      /ChunkLoadError/.test(event.transaction || '') ||
      /Remote Config/.test(event.transaction || '') ||
      /evaluating 'a.fa'/.test(event.transaction || '') ||
      /Minified React/.test(event.transaction || '') ||
      /Activation code not found/.test(event.transaction || '') ||
      /The operation is insecure/.test(event.transaction || '') ||
      /isReCreate is not defined/.test(event.transaction || '') ||
      /globalThis is not defined/.test(event.transaction || '') ||
      /Transaction timed out due/.test(event.transaction || '') ||
      /FirebaseError/.test(event.transaction || '') ||
      /Database/.test(event.transaction || '') ||
      /request was interrupted/.test(event.transaction || '')
    ) {
      return null;
    }
    if (
      event?.level === 'error' &&
      event?.extra?.operationName === 'AuthProviderViewerSessionEvent'
    ) {
      return null;
    }
    return event;
  },
});

export interface CreateClientOptions {
  typePolicies?: TypePolicies;
}

function createApolloClient(options?: CreateClientOptions) {
  const server = isServer();
  const wsLinks = server
    ? undefined
    : [
        new GraphQLWsLink(createClient({ url: WsApi })),
        new GraphQLWsLink(createClient({ url: WsApiV2 })),
      ];

  const httpLinkV1 = createHttpLink({
    uri: server ? ServerApi : ClientApi,
    credentials: 'include',
  });
  const httpLinkV2 = createHttpLink({
    uri: server ? ServerApiV2 : ClientApiV2,
    credentials: 'include',
  });

  const errorLink = onError(({ graphQLErrors, operation }) => {
    if (graphQLErrors && graphQLErrors?.length) {
      const scope = Sentry.getCurrentScope();
      scope.setTag('apollo', 'apollo');
      scope.setLevel('error');
      const user = scope.getUser();
      scope.setExtras({
        user,
        variables: operation.variables,
        errors: graphQLErrors,
        operationName: operation.operationName,
      });

      const er = head(graphQLErrors);

      if (er?.path?.includes('viewerSessionEvent')) {
        return;
      }

      const firstError = er?.message || 'Apollo Error';
      Sentry.captureException(new Error(firstError));
    }
  });

  const handlerHttp = split(
    ({ getContext }) => {
      const context = getContext();
      return context.v2 as boolean;
    },
    httpLinkV2,
    httpLinkV1,
  );

  const handlerWss = wsLinks
    ? split(
        ({ getContext }) => {
          const context = getContext();
          return context.v2 as boolean;
        },
        wsLinks[1]!,
        wsLinks[0],
      )
    : undefined;

  const link = handlerWss
    ? split(
        ({ query }) => {
          const def = getMainDefinition(query);
          return def.kind === 'OperationDefinition' && def.operation === 'subscription';
        },
        handlerWss,
        handlerHttp,
      )
    : handlerHttp;

  return new ApolloClient({
    ssrMode: server,
    cache: new InMemoryCache({ typePolicies: options?.typePolicies }),
    link: from([errorLink, link]),
    connectToDevTools: true,
  });
}

function mergeApolloCache(
  client: ApolloClient<NormalizedCacheObject>,
  state?: NormalizedCacheObject,
) {
  if (!state) {
    return;
  }

  const existingCache = client.extract();

  const data = merge(state, existingCache, {
    arrayMerge: (destinationArray: object[], sourceArray: object[]) => [
      ...sourceArray,
      ...destinationArray.filter((d) => sourceArray.every((s) => !isEqual(d, s))),
    ],
  });

  client.restore(data);
}

const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';

interface ApolloState {
  [APOLLO_STATE_PROP_NAME]?: NormalizedCacheObject;
}

function getApolloState(props: ApolloState): NormalizedCacheObject | undefined {
  return props?.[APOLLO_STATE_PROP_NAME];
}

let globalApolloClient: ApolloClient<NormalizedCacheObject>;

export function initializeApollo(
  initialState?: NormalizedCacheObject,
  options?: CreateClientOptions,
) {
  const apolloClient = globalApolloClient ?? createApolloClient(options);
  mergeApolloCache(apolloClient, initialState);
  // For SSG and SSR always create a new Apollo Client
  if (isServer()) {
    return apolloClient;
  }
  // Create the Apollo Client once in the client
  if (!globalApolloClient) {
    globalApolloClient = apolloClient;
  }

  return apolloClient;
}

// export function useApollo(pageProps) {
//   const state = pageProps[APOLLO_STATE_PROP_NAME];
//   const store = useMemo(() => initializeApollo(state), [state]);
//   return store;
// }

export function addApolloState<T extends Record<string, unknown>>(
  client: ApolloClient<NormalizedCacheObject>,
  props: T,
): T & ApolloState {
  return { ...props, [APOLLO_STATE_PROP_NAME]: client.cache.extract() };
}

export function ApolloProviderWrapper({
  children,
  pageProps,
  options,
}: {
  children: React.ReactNode;
  pageProps: ApolloState;
  options?: CreateClientOptions;
}) {
  const client = useMemo(
    () => initializeApollo(getApolloState(pageProps), options),
    [options, pageProps],
  );
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
