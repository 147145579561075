import { useCheckForDomainLazyQuery } from '@/graphql/generated/query';
import { EXAM_US_URL, ORIGINAL_DOMAINS } from '@/lib/routes';
import { useEffect } from 'react';

interface Props {
  children: React.ReactNode;
}

function DomainNotFoundRedirection({ children }: Props) {
  const [check, { loading }] = useCheckForDomainLazyQuery();

  useEffect(() => {
    if (typeof window !== 'undefined' && window.location.hostname !== 'localhost') {
      const parts = window.location.hostname.split('.');

      const subdomain = parts[0];

      if (!ORIGINAL_DOMAINS.includes(subdomain as string)) {
        void check({
          variables: {
            domain: subdomain!,
            requireApproved: true,
          },
          onCompleted: (data) => {
            if (!data.checkForDomain.exist) {
              window.location.href = EXAM_US_URL;
            }
          },
        });
      }
    }
  }, [check]);

  return loading ? <div /> : <>{children}</>;
}

export default DomainNotFoundRedirection;
