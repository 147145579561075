import dynamic from 'next/dynamic';

export const InviteFriendsPopupLazy = dynamic(
  () => import('@/components/popups/InviteFriendsPopup'),
  {
    ssr: false,
  },
);

export const NotificationPopupLazy = dynamic(
  () => import('@/components/popups/NotificationPopup'),
  {
    ssr: false,
  },
);

export const ActiveAccountPopupLazy = dynamic(
  () => import('@/components/popups/ActiveAccountModal'),
  { ssr: false },
);

export const NoAccessPopupLazy = dynamic(() => import('@/components/popups/NoAccessModal'), {
  ssr: false,
});

export const PlacementTestModalLazy = dynamic(
  () => import('@/components/container/modal/PlacementTestModal/PlacementTest'),
  { ssr: false },
);

export const PlacementTestResultModalLazy = dynamic(
  () => import('@/components/container/modal/PlacementTestModal/PlacementTestResult'),
  { ssr: false },
);

export const JoinClassModalLazy = dynamic(
  () => import('@/components/container/modal/JoinClassModal/JoinClassModal'),
  { ssr: false },
);

export const PremiumModalLazy = dynamic(
  () => import('@/components/container/modal/PremiumModal/PremiumModal'),
  { ssr: false },
);

export const SummaryYearModalLazy = dynamic(
  () => import('@/components/container/modal/SummaryYear/SummaryYearModal'),
  { ssr: false },
);

export const NetworkErrorPopupLazy = dynamic(() => import('@/components/popups/NetworkError'), {
  ssr: false,
});
export const EndSessionLazy = dynamic(() => import('@/components/container/modal/EndSession'), {
  ssr: false,
});

export const SummaryMonthModalLazy = dynamic(
  () => import('@/components/container/modal/SummaryYear/SummaryMonthModal'),
  {
    ssr: false,
  },
);
export const WhatNewsModalLazy = dynamic(() => import('@/components/container/modal/WhatNews'), {
  ssr: false,
});

export const ModalNextPartLazy = dynamic(() => import('@/components/ielts/common/ModalNextPart'), {
  ssr: false,
});

export const ModalSkipTestMicLazy = dynamic(
  () => import('@/components/ielts/common/ModalSkipTestMic'),
  {
    ssr: false,
  },
);

export const ModalLeaveCheckPointLazy = dynamic(
  () => import('@/components/ielts/common/ModalLeaveCheckPoint'),
  {
    ssr: false,
  },
);
