import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import vi from 'dayjs/locale/vi';
import isBetween from 'dayjs/plugin/isBetween';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';
import weekYear from 'dayjs/plugin/weekYear';

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(quarterOfYear);
dayjs.extend(weekOfYear);
dayjs.extend(isBetween);
dayjs.extend(localeData);
dayjs.extend(weekYear);

dayjs.locale({
  ...vi,
  weekStart: 1,
});

export const FORMAT_TIME = 'HH:mm:ss';
export const FORMAT_TIME_DATE = 'HH:mm, DD-MM-YYYY';
export function getDuration(value: string, format = FORMAT_TIME) {
  const time = dayjs(value, format);

  return dayjs.duration({
    hours: time.hour(),
    minutes: time.minute(),
    seconds: time.second(),
    milliseconds: time.millisecond(),
  });
}

const time = dayjs;

export type { Duration } from 'dayjs/plugin/duration';

export default time;

export { type ConfigType } from 'dayjs';
