import { ENVIRONMENT } from '@/lib/config';

import Link from 'next/link';
import { useCallback, useEffect, useState } from 'react';

function WrongPage() {
  // const remoteConfig = useRemoteConfig();
  // const { user, learnerProfiles } = useAuth();

  const [login, setLogin] = useState(true);
  const handlerOnChange = useCallback((e: { target: { value: string } }) => {
    const val = e.target.value;
    if (val === 'ok') {
      setLogin(true);
      localStorage.setItem('passdev', 'ok');
    }
  }, []);
  //
  // useEffect(() => {
  //   if (isClient() && !ENVIRONMENT.IS_DEV && !remoteConfig.enableDebug) {
  //     // eslint-disable-next-line @typescript-eslint/no-var-requires,global-require,unicorn/prefer-module
  //     const devtool = require('disable-devtool');
  //     // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  //     devtool({
  //       disableMenu: false,
  //       md5: '444bcb3a3fcf8389296c49467f27e1d6',
  //       url: 'https://flyer.vn',
  //       interval: 600,
  //       tkName: 'bypass',
  //       ignore: () => {
  //         const domain = window.location.hostname;
  //         const data = window.localStorage.getItem('passdev');
  //         if (domain.includes('staging') || domain.includes('demo.flyer')) {
  //           return true;
  //         }
  //
  //         if (data === 'ok') {
  //           return true;
  //         }
  //
  //         if (user?.adminRole) {
  //           return true;
  //         }
  //
  //         if (user?.email?.includes('@flyer.us')) {
  //           return true;
  //         }
  //
  //         if (
  //           user?.phoneNumber?.includes('963454592') ||
  //           user?.phoneNumber?.includes('989082518') ||
  //           user?.phoneNumber?.includes('385465101') ||
  //           user?.phoneNumber?.includes('385465101') ||
  //           user?.phoneNumber?.includes('988067911')
  //         ) {
  //           return true;
  //         }
  //
  //         const listDomain = flatten(learnerProfiles?.map((el) => el?.domains));
  //
  //         const isPer = listDomain?.some(
  //           (el) => el?.domainName?.includes('nam') || el?.domainName?.includes('linhtest'),
  //         );
  //
  //         if (isPer) {
  //           return true;
  //         }
  //       },
  //     });
  //   }
  // }, [learnerProfiles, remoteConfig.enableDebug, user?.adminRole, user?.email, user?.phoneNumber]);

  useEffect(() => {
    if (!ENVIRONMENT.IS_DEV) {
      return;
    }
    if (localStorage.getItem('passdev') === 'ok') {
      setLogin(true);
    } else {
      setLogin(false);
    }
  }, []);

  if (!ENVIRONMENT.IS_DEV) {
    return;
  }

  if (login) {
    return;
  }
  return (
    <div className="absolute top-0 left-0 w-full h-full bg-amber-50 z-[99900000]">
      <input type="text" onChange={handlerOnChange} />
      <div className="text-center mt-[100px]">
        <h1>Bạn đang vào nhầm trang web. Hãy truy cập link bên dưới lại nhé.</h1>
        <Link href="https://exam.flyer.vn">https://exam.flyer.vn</Link>
      </div>
    </div>
  );
}

export default WrongPage;
